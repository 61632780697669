import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import { Link, NavLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { auth, org, rightKeys, Target, target, TargetName, user } from "@src/Defines";
import { SubOrg, SubOrganizationsService } from "@src/rest";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import secureLocalStorage from "react-secure-storage";
import {
  Apps,
  Assistant,
  BugReport,
  DevicesOther,
  DocumentScanner,
  Flag,
  Groups, InsertChartOutlined,
  Payment, PublishedWithChanges, RestartAlt,
  SecurityUpdateWarning,
  Settings,
  Vibration,
  WorkHistory
} from "@mui/icons-material";

export const sidebar = [
  {
    title: "Monitor", items: [
      { icon: <DevicesOther/>, text: 'Fleet Overview', to: 'devices' },
      { icon: <SecurityUpdateWarning/>, text: 'Alerts', to: 'alerts' },
      { icon: <InsertChartOutlined/>, text: 'Distribution', to: 'dashboard' },
    ]
  },
  {
    title: "Analyze", items: [
      { icon: <Assistant/>, text: 'Smart Agent', to: 'chat' },
      { icon: <BugReport/>, text: 'Field Issues', to: 'issues' },
      { icon: <RestartAlt/>, text: 'Reboots', to: 'reboots' }
    ]
  },
  {
    title: "Deploy",
    items: [
      { icon: <Vibration/>, text: 'Groups', to: 'groups' },
      { icon: <Apps/>, text: 'Software', to: 'packages' },
      { icon: <Flag/>, text: 'Feature Flags', to: 'flags' },
      { icon: <Settings/>, text: 'Configuration', to: 'configuration' },
      { icon: <PublishedWithChanges/>, text: 'Rollouts', to: 'rollouts' },
      { icon: <WorkHistory/>, text: 'Automations', to: 'jobs' },
    ]
  },
  {
    title: "Admin", items: [
      { icon: <Groups/>, text: 'Users', to: 'users', admin: true },
      { icon: <Payment/>, text: 'Billing', to: 'billing' },
      { icon: <DocumentScanner/>, text: 'API Reference', to: 'api' }
    ]
  }
]


export const DashDrawer = () => {

  const [groups, setGroups] = useState<Array<SubOrg> | undefined>(undefined);

  useEffect(() => {

    SubOrganizationsService.listSubOrgNames(auth(), org()).then(response => {
      setGroups(response ?? [])
    }, err => {
    })
  }, []);


  const selectTarget = (target: string) => {
    if (target !== org()) {
      secureLocalStorage.setItem(Target, target)

      const name = groups?.find(it => it.sub_org_id == target)?.sub_org_name
      secureLocalStorage.setItem(TargetName, name ?? "")

    } else {
      secureLocalStorage.removeItem(Target)
      secureLocalStorage.removeItem(TargetName)

    }
    window.location.reload();
  }

  const colors = [
    'rgba(138,198,192,0.2)',  // A light beige
    'rgba(166,197,229,0.2)',  // A very light mint green
    'rgba(70,99,148,0.2)',  // A light sky blue
    'rgba(148,70,121,0.2)',  // A very light coral
  ];

  const rights = user()?.rights ?? {}

  const isVisibleItem = (key: string) => {
    return rights[key] == 'write' || rights[key] == 'read'
  }

  const isVisible = (item: { icon: any, text: string, to: string }) => {

    if (rightKeys.indexOf(item.to) >= 0) {
      return isVisibleItem(item.to)
    }

    return true;
  }

  return <>
    <Toolbar>
      <Link to="/dash">
        <img src="/images/logo-blue.png" height="34" className="logo-dark-mode" alt=""/>
      </Link>
    </Toolbar>
    <Divider/>


    <Box sx={{ px: 2 }}>
      <FormControl variant="standard" fullWidth size="small">
        <InputLabel>Target</InputLabel>

        <Select label="Target" value={target() ?? org()}
                onChange={(event) => selectTarget(event.target.value as string)}>

          <MenuItem key={org()} value={org()}>Org: {org()} </MenuItem>
          {groups?.map((it: SubOrg) =>
            <MenuItem key={it.sub_org_id} value={it.sub_org_id}>Group: {it.sub_org_name} </MenuItem>
          )}
        </Select>

      </FormControl>
    </Box>

    {sidebar.map((it, index) => (
      <div key={index} style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '8px', // Reduced margin between groups
        backgroundColor: colors[index % colors.length],
        padding: '4px', // Added padding for better spacing
      }}>
        <div style={{
          writingMode: 'vertical-rl',
          transform: 'rotate(180deg)',
          marginRight: '8px',
          alignSelf: 'center',
          textTransform: 'uppercase',
          fontWeight: 'normal',
        }}>
          &nbsp;{it.title}
        </div>
        <List style={{ width: '100%' }}> {/* Ensure List takes full width */}
          {it.items.map((item, ind) => (
            <ListItem disablePadding key={ind}>
              <ListItemButton
                disabled={!isVisible(item)}
                component={NavLink}
                to={item.to}
                selected={window.location.pathname.startsWith('/dash/' + item.to.slice(0, -1))}
                sx={{
                  width: '100%', // Make ListItemButton full width
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  "&.Mui-selected .MuiListItemText-primary": { fontWeight: 'bold' }, // Make text bold when selected
                }}
              >
                <ListItemIcon sx={{ minWidth: '30px' }}> {/* Adjust the minWidth to reduce spacing */}
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text}/>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    ))}
  </>
};
