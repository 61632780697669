import * as React from "react";
import { Suspense } from "react";
import NavBar from "../content/public/components/NavBar";
import { Route, Routes } from "react-router-dom";
import Footer from "../content/public/components/Footer";
import { Backdrop, Container } from "@mui/material";
import useJiraWidget from "@src/hooks/useJiraWidget";

const Login = React.lazy(() => import("../content/public/pages/Login"));
const Register = React.lazy(() => import("../content/public/pages/Register"));
const Confirm = React.lazy(() => import("../content/public/pages/Confirm"));
const Home = React.lazy(() => import("../content/public/pages/Home"));
const AboutUs = React.lazy(() => import("../content/public/pages/AboutUs"));
const ForgotPassword = React.lazy(() => import("../content/public/pages/ForgotPassword"));
const Features = React.lazy(() => import("../content/public/pages/Features"));
const Terms = React.lazy(() => import("../content/public/pages/Terms"));
const NewPassword = React.lazy(() => import("../content/public/pages/NewPassword"));
const Pricing = React.lazy(() => import("../content/public/pages/Pricing"));
const Blog = React.lazy(() => import("../content/public/pages/Blog"));
const BlogEntry = React.lazy(() => import("../content/public/pages/BlogEntry"));


const routes = [
  { path: "/login", element: Login },
  { path: "/register", element: Register },
  { path: "/confirm", element: Confirm },
  { path: "/forgot-password", element: ForgotPassword },
  { path: "/new-password", element: NewPassword },
  { path: "/about-us", element: AboutUs },
  { path: "/features", element: Features },
  { path: "/terms", element: Terms },
  { path: "/pricing", element: Pricing },
  { path: "/blog", element: Blog },
  { path: "/blog/:id", element: BlogEntry },
  { path: "/", element: Home },
];

const loading = (<Backdrop
  sx={{ color: '#fff', minHeight: "100vh", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
  <div className="custom-loader"></div>
</Backdrop>);

const PublicLayout = () => {
  // useJiraWidget()

  return <React.Fragment>

    <NavBar/>
    <section className="section bg-light"
             style={{ backgroundImage: `url(/images/1.png)`, backgroundSize: "contain" }}>

      <Container className=" min-vh-100" sx={{pt: 10, pb: 15}}>

        <Suspense fallback={loading}>
          <Routes>
            {routes.map((route, idx) => {
              return (
                route.element && (
                  <Route key={idx} path={route.path} element={<route.element/>}/>
                )
              );
            })}
          </Routes>
        </Suspense>
      </Container>
    </section>
    <Footer/>

  </React.Fragment>
}

export default PublicLayout;
